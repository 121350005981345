import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';


function App() {



  
  const [puestos, setPuestos] = useState([]); useEffect(() => { axios.get('https://arteyefectos.com/adm/consulta.php') .then(response => { const data = response.data ; console.log(response); const allPuestos = data.flatMap(item => JSON.parse(item.puestos)); setPuestos(allPuestos); }) .catch(error => { console.error('Error fetching data:', error); }); }, []);

    const [formulario, setFormulario] = useState(false);
    const [selectCurso, setSelectCurso] = useState(null);
    const [listCursos, setListCursos] = useState([
      { titulo: "Nuevos Relatos V - ARMAGEDÓN", descripcion: "Nuevos Relatos es un evento exclusivo que celebra el talento emergente de la comunidad cinematográfica de Venezuela, presentando los cortometrajes realizados por estudiantes de la Escuela de Cine de Carabobo. Los asistentes podrán disfrutar de la experiencia de caminar por la alfombra roja, hacerse fotos con cineastas y actores, y establecer contactos valiosos en la industria. No te pierdas esta oportunidad única de sumergirte en el vibrante mundo del cine venezolano y descubrir lo que está por venir el próximo año.", fecha: "Domingo 15 de Diciembre, 2024", hora: "09:00 AM", precio: "2da Preventa: 600 Bs (Se agotan!)", descuento: "¡Aprovecha las Preventas! las entradas en puerta costaran 1000bs (20$) + cotufas", imagen: "https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2F467717760_938811234317470_2862762004581732088_n.jpeg?alt=media&token=ba3902c2-bff1-413e-8a25-a7478a5aba00", mensualidad: null, evento: true },

      { titulo: "Estudios Integrales de Cine (Sab)", descripcion: "Descubre el apasionante mundo del cine con nuestros Estudios Integrales, que abarcan 16 materias clave, como Guion, Producción, Fotografía y Montaje. En 380 horas distribuidas en 12 meses, aprenderás a desarrollar tus propios proyectos cinematográficos y dominarás cada etapa de la producción: preproducción, producción, postproducción y comercialización. Prepárate para destacar en la industria audiovisual y convertirte en el cineasta que siempre has querido ser. ¡Inscríbete y comienza tu aventura cinematográfica!", fecha: "Enero, 2025", hora: "09:00 AM", precio: "60$", descuento: " Inscríbete ahora y obtén un 50% de descuento en la matrícula. ¡Solo paga $30 (1500 Bs)! No dejes pasar esta oportunidad.", imagen: "https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2Fportadacinesabatino.jpeg?alt=media&token=fcd24a57-0dea-497a-b96f-e7750c427e2e&_gl=1*flrthr*_ga*NDYwNzM2ODA4LjE2OTc5MjgxMDU.*_ga_CW55HF8NVT*MTY5NzkyODEwNC4xLjEuMTY5NzkyODMwOS40MC4wLjA.", mensualidad: "40$" },
      { titulo: "Actuación frenta a Cámaras", descripcion: "Este curso está diseñado para talentos que desean dominar el arte de actuar frente a cámaras y conectar con profesionales de la industria audiovisual. A lo largo de aproximadamente 6 meses, ganarás experiencia interpretando diversos personajes, lo que te ayudará a sobresalir en audiciones. Además, trabajarás en la creación de tu demo reel, esencial para tu carrera internacional. Las clases son personalizadas y se complementan con 8 metas en géneros como drama, romance y acción. ¡Prepárate para brillar en el mundo del cine!", fecha: "Enero, 2025", hora: "09:00 AM", precio: "20$", descuento: " Inscríbete ahora y obtén un 50% de descuento en la matrícula. ¡Solo paga $10 (500 Bs)! No dejes pasar esta oportunidad.", imagen: "https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2FFrenteacamara.png?alt=media&token=a1c77eaf-60e1-45ea-96e9-bb974254c6b8", mensualidad: "60$" },
      { titulo: "Estudios Integrales de Fotografía (Sab)", descripcion: "Descubre el arte de la fotografía con nuestros Estudios Integrales, que abarcan 6 áreas clave: Composición de la Imagen, Equipo Técnico, Semiótica Visual, Técnicas de Iluminación, Géneros Fotográficos y Retoque Fotográfico. Con una duración de 96 horas distribuidas en 6 meses, este curso no solo te brindará conocimientos teóricos, sino que también culminará en la creación de un photobook impreso y tu participación en una exposición fotográfica. ¡Transforma tu pasión por la fotografía en una experiencia inolvidable!", fecha: "Enero, 2025", hora: "09:00 AM", precio: "20$", descuento: " Inscríbete ahora y obtén un 50% de descuento en la matrícula. ¡Solo paga $10 (500 Bs)! No dejes pasar esta oportunidad.", imagen: "https://firebasestorage.googleapis.com/v0/b/arteyefectosfilms.appspot.com/o/imgweb2023%2Fportadafotografia.jpeg?alt=media&token=731479ea-afbd-4b01-adea-0fe5b0c4e2a7&_gl=1*4dmzli*_ga*NDYwNzM2ODA4LjE2OTc5MjgxMDU.*_ga_CW55HF8NVT*MTY5NzkyODEwNC4xLjEuMTY5NzkyODQ3MC4zMi4wLjA.", mensualidad: "40$" },

    ]);

    const [nickname, setNickname] = useState('');
    const [formData, setFormData] = useState({});
    const [message, setMessage] = useState('');
    const [cargando, setCargando] = useState(false);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value, curso: selectCurso.titulo, vendedor: nickname });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await fetch('insert.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(formData).toString(),
        });

        setCargando(true);

        const result = await response.json();
        if (result.success) {
          setMessage('Registro exitoso, confirmaremos la información y en breve nos estaremos poniendo en contacto contigo. Si tienes alguna pregunta puedes contactarnos al +584146910653');
          setCargando(true);

          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
          setMessage('Error: ' + result.message);
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setCargando(false);

        }
      } catch (error) {
        setMessage('Error: ' + error.message);
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setCargando(false);

      }
    };

    useEffect(() => {
      const path = window.location.pathname;
      const nicknameFromPath = path.split('/').pop();
      if (!nicknameFromPath) {
        window.location.href = 'https://escuela.arteyefectos.com';
      } else {
        setNickname(nicknameFromPath);
      }
    }, []);

    return (

      <div>

        <div className="logo-container">
          <img src="https://escuela.arteyefectos.com/img/logo_escuela_aye_sd.png" className="logo" alt="Escuela de Cine" />
        </div>
        {message && <div className="alert alert-info">{message}</div>}

        {!selectCurso ? (
          <div className={nickname ? "container" : "hidden"} id="courseContainer">
            <h2>Nuestros Cursos y Talleres</h2>
            <div className="row g-3">
              <div className="col-12">
                {listCursos.map((item) => (
                  <div className="card" key={item.titulo}>
                    <img src={item.imagen} alt="Cortometrajes" className="course-image" />
                    <div className="card-body text-center">
                      <h5 className="card-title">{item.titulo}</h5>
                      <p className="card-text">{item.descripcion}</p>
                      {item.fecha && (
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Fecha</th>
                              <th scope="col">Hora</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item.fecha}</td>
                              <td>{item.hora}</td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      <h4 className="card-text"><strong>{item.evento ? "" : "Inscripción:"}</strong> {item.precio}</h4>
                      {item.mensualidad && <h5 className="card-text"><strong>Mensualidad/Cuotas:</strong> {item.mensualidad}</h5>
                      }
                      <p className="card-text alert alert-success"><strong><i className="fa fa-gift" aria-hidden="true" /></strong>{item.descuento}</p>
                      <p><button className="btn btn-primary col-6" onClick={() => { setSelectCurso(item); window.scrollTo({ top: 0, behavior: 'smooth' }) }}><i className="fa fa-pencil" aria-hidden="true" />{item.evento ? " Comprar Entrada" : " Registrate Aquí"}</button></p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={!cargando ? "container" : "container hidden"} id="formContainer">
            <h5>{selectCurso.evento ? "Comprar Entrada" : "Curso a Inscribir:"}</h5>

            <h2>{selectCurso.titulo}</h2>
            <h5>Formas de Pago:</h5>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">PagoMovil</h5>
                <p className="card-text"><strong>Banco:</strong> Banco de Venezuela</p>
                <p className="card-text"><strong>Teléfono:</strong> 0424 4006995</p>
                <p className="card-text"><strong>Cédula:</strong> 21242299</p>
                <p onClick={() => navigator.clipboard.writeText('Banco de Venezuela 21242299 04244006995 ')} className="card-text"><button type="button" className="btn btn-secondary col-12">Copiar Datos PM</button></p>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Transferencia</h5>
                <p className="card-text"><strong>Banco:</strong> Banco de Nacional de Credito</p>
                <p className="card-text"><strong>Titular:</strong> Arteyefectos Producciones.C.A</p>
                <p className="card-text"><strong>Cuenta:</strong> 0191-0127-48-2100070103</p>
                <p className="card-text"><strong>Rif:</strong> J-407608576</p>
                <p onClick={() => navigator.clipboard.writeText('Banco de Nacional de Credito Arteyefectos Producciones.C.A 0191-0127-48-2100070103 J-407608576')} className="card-text"><button type="button" className="btn btn-secondary col-12">Copiar Datos PM</button></p>

              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">PayPal</h5>
                <p className="card-text"><strong>Email:</strong> arteyefectospro@gmail.com</p>
              </div>
            </div>
            <form onSubmit={handleSubmit}>

              <br></br>

              <h5 className="card-title">Datos de Pago (Bs/Usd)</h5>
              <div className="mb-3">
                <label htmlFor="cantidad" className="form-label">Monto Pagado</label>
                <input
                  type="number"
                  className="form-control"
                  id="cantidad"
                  name="cantidad"
                  value={formData.cantidad || ''}
                  onChange={handleChange}
                  required
                  min={1}
                  step="0.01" // Permite números con dos decimales
                />            </div>
              <div className="mb-3">
                <label htmlFor="referencia" className="form-label">Numero Referencia - Banco</label>
                <input type="text" className="form-control" id="referencia" name="referencia" value={formData.referencia || ''} onChange={handleChange} />
              </div>
              <h5 className="card-title">Datos de Contacto</h5>
              <div className="mb-3">
                <label htmlFor="nombre" className="form-label">Nombre </label>
                <input type="text" className="form-control" id="nombre" name="nombre" value={formData.nombre || ''} onChange={handleChange} required />
              </div>
              <div className="mb-3">
                <label htmlFor="apellido" className="form-label">Apellido </label>
                <input type="text" className="form-control" id="apellido" name="apellido" value={formData.apellido || ''} onChange={handleChange} required />
              </div>
              <div className="mb-3">
                <label htmlFor="telefono" className="form-label">Teléfono</label>
                <input type="text" className="form-control" id="telefono" name="telefono" value={formData.telefono || ''} onChange={handleChange} required />
              </div>
              <div className="mb-3">
                <label htmlFor="correo" className="form-label">Correo Electronico</label>
                <input type="email" className="form-control" id="correo" name="correo" value={formData.correo || ''} onChange={handleChange} required />
              </div>
              <button type="submit" className="btn btn-primary w-100"><i className="fa fa-money" aria-hidden="true" disabled={cargando} /> Registrar Pago</button>
            </form>
            <h6 style={{ fontSize: "0.8em" }}>Términos y Condiciones:</h6>


            {selectCurso.evento ?
              <ul style={{ fontSize: "0.8em" }}>
                <li><strong>Precios Sujeto a Cambios:</strong> Los precios pueden cambiar sin previo aviso, así que te recomendamos estar atento a el precio vigente.</li>
                <li><strong>No Reembolsos:</strong> No se realizarán reembolsos, por lo que te pedimos tenerlo en cuenta al adquirir tu entrada.</li>
                <li><strong>Derecho de Admisión:</strong> Nos reservamos el derecho de admisión para garantizar la mejor experiencia para todos.</li>
              </ul>

              : <ul style={{ fontSize: "0.8em" }}>
                <li><strong>Pagos Puntuales:</strong> Agradecemos que realices el pago de las cuotas durante los primeros 5 días del mes. Recuerda que los retrasos pueden generar un cargo adicional.</li>
                <li><strong>Precios Sujeto a Cambios:</strong> Los precios pueden cambiar sin previo aviso, así que te recomendamos estar atento a nuestras actualizaciones.</li>
                <li><strong>No Reembolsos:</strong> No se realizarán reembolsos, por lo que te pedimos tenerlo en cuenta al inscribirte.</li>
                <li><strong>Estudiantes Activos:</strong> Solo los estudiantes activos podrán permanecer en el establecimiento para mantener un ambiente de aprendizaje óptimo.</li>
                <li><strong>Derecho de Admisión:</strong> Nos reservamos el derecho de admisión para garantizar la mejor experiencia para todos.</li>
              </ul>}

            <br></br>
            <button className="btn btn-secondary col-12 py-2" onClick={() => { setSelectCurso(null); setFormData({}); window.scrollTo({ top: 0, behavior: 'smooth' }) }}><i className="fa fa-undo" aria-hidden="true" /> Regresar</button>
          </div>
        )}
        <div className=""><a href="https://escuela.arteyefectos.com">

          <botton className="btn btn-dark container">Visitar Portal</botton></a>
          <p className='col'>Escuela de Cine - Arteyefectos Producciones.C.A
          </p>      </div>
      </div>



    );
  }

export default App;
